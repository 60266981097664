import {OATH_HEADERS} from '../header.js'
import API from '@/API'
import axios from 'axios'

const events = {
  FIND      : "find",
  SEARCH    : "search",
  ALL       : "all",
  CREATE    : "create",
  UPDATE    : "update",
  DELETE    : "delete",
}

const getDefaultState = () => {
  return {
    all: [],
  }
}

export default {

  namespaced: true,

  state: getDefaultState(),

  getters: {
  },

  actions: {

    [events.ALL] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.options.all, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.SEARCH] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.options.search, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.FIND] (context, id) {
      return new Promise((resolve, reject) => {
        axios.get(API.options.find+"/"+id, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.CREATE] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.options.create, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.UPDATE] (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API.options.update, data, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [events.DELETE] (context, id) {
      return new Promise((resolve, reject) => {
        axios.post(API.options.delete, {id:id}, {headers: OATH_HEADERS()})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

  },

  mutations: {
    reset (state) {
      Object.assign(state, getDefaultState())
    },

  }
}


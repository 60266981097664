<template>
  <span class="inline-edit d-flex align-stretch position-relative">
    <v-overlay absolute :value="loading"><v-progress-circular indeterminate size="20"></v-progress-circular></v-overlay>

    <span class="inline-edit-overlay d-block" v-if="!isEditing" @click="showInput">
      <span v-if="showOperator">{{ plus ? '+' : '-' }}</span>
      {{ edit!=null?edit:placeholder }}
      <span v-if="percentable">{{ prc ? '%' : '€' }}</span>
    </span>
    <span class="inline-edit-input" v-else>
      <v-text-field v-model="edit" v-float @blur="onSave" @input="onChange" ref="inlInput" @focus="$event.target.select()"
        :placeholder="placeholder"
        class="d-inline x-dense"
        dense hide-details outlined
        :disabled="disabled"
        @keydown.enter="test"
      >
        <template v-slot:prepend-inner v-if="showOperator">
          <v-icon small @click="toggleAdjust" color="primary" :disabled="!adjustable">{{ plus ? 'mdi-plus' : 'mdi-minus' }}</v-icon>
        </template>
        <template v-slot:append v-if="percentable">
          <v-icon dark small v-on:click="togglePercent" color="primary">
            {{ prc ? 'mdi-percent-outline' : 'mdi-currency-eur' }}
          </v-icon>
        </template>
      </v-text-field>
    </span>
  </span>
</template>

<style>
  .inline-edit {
  }
  .inline-edit .inline-edit-overlay{
    height:100%;
    width:100%;
    cursor: text;
  }
  .inline-edit .inline-edit-overlay:hover {
    border: 1px solid currentColor;
    border-radius: 4px;
  }
</style>

<script>
  export default {
    props: {
      value: {
        type: [String,Number],
        default: null
      },

      placeholder: {
        type: [String,Number],
        default: null
      },

      loading: {
        type: Boolean,
        default: false
      },

      // are we toggling isEditing?
      alwaysShow: {
        type: Boolean,
        default: false
      },

      // if can toggle percentable
      percentable: {
        type: Boolean,
        default: false
      },

      adjustable: {
        type: Boolean,
        default: false
      },

      disabled: {
        type: Boolean,
        default: false
      },

      percent: {
        type: Boolean,
        default: false
      },

      increase: {
        type: Boolean,
        default: false
      },

      showOperator: {
        type: Boolean,
        default: true
      },

    },

    data() {
      return {
        edit: this.value,
        isEditing: this.alwaysShow,
        hasChanged: false,
        plus: this.increase,
        prc: this.percent,
      }
    },

    watch: {
      value(val) {
        this.edit = val
      },
    },

    methods: {
      showInput() {
        if (!this.alwaysShow) {
          this.isEditing=true
          this.$emit('update:editing', this.isEditing)
        }
        this.$nextTick(()=>{
          this.$refs.inlInput.focus()
        })
      },

      toggleAdjust() {
        this.plus = !this.plus
        this.onChange()
        this.onSave()
      },

      togglePercent() {
        this.prc = !this.prc
        this.onChange()
        this.onSave()
      },

      onChange() {
        this.hasChanged=true
      },

      onSave() {
        if (this.hasChanged) {
          this.$emit("input", this.edit)
          this.$emit('update:percent', this.prc)
          this.$emit('update:increase', this.plus)
        }
        if (!this.alwaysShow) {
          this.isEditing=false
          this.$emit('update:editing', this.isEditing)
        }
        this.hasChanged=false
      },

      test(e) {
        console.log("emit enter")
        this.$emit('keydown:enter', e)
      },
    }

  }
</script>

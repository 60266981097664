import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import auth from './middleware/auth';

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      redirect: "content/trips",
      component: () => import("@/layouts/full-layout/Layout"),
      meta: {
        middleware: auth,
      },

      children: [
        // Content
        {
          name: "content",
          path: "content",
          component: () => import("@/views/content/index"),
          meta: {middleware: auth},
          children: [
            {
              path: "trips",
              component: () => import("@/views/content/trips/index"),
              meta: {middleware: auth},
              children: [
                {
                  name: "trips_list",
                  path: "/",
                  component: () => import("@/views/content/trips/TripsList"),
                  meta: {middleware: auth,cudl:'list'},
                },
                {
                  name: "trips_create",
                  path: "create",
                  component: () => import("@/views/content/trips/TripsEditForm"),
                  meta: {middleware: auth,cudl:'create'},
                },
                {
                  path: ":id",
                  component: () => import("@/views/content/trips/TripsView"),
                  meta: {middleware: auth,cudl:'update'},
                  children: [
                    {
                      name: "trips_edit",
                      path: "/",
                      component: () => import("@/views/content/trips/TripsEdit"),
                      meta: {middleware: auth,cudl:'update'},
                    },
                    {
                      name: "trips_booking",
                      path: "bookings",
                      component: () => import("@/views/content/trips/TripsBooking"),
                      meta: {middleware: auth,cudl:'update'},
                    },
                    {
                      name: "periods_list",
                      path: "periods",
                      component: () => import("@/views/content/trips/periods/PeriodsList"),
                      meta: {middleware: auth,cudl:'periods_list'},
                    },
                    {
                      name: "periods_create",
                      path: "periods/create",
                      component: () => import("@/views/content/trips/periods/PeriodsEditForm"),
                      meta: {middleware: auth,cudl:'periods_create'},
                    },
                    {
                      path: "periods/:pid/:copy?",
                      component: () => import("@/views/content/trips/periods/PeriodsView"),
                      meta: {middleware: auth,cudl:'periods_update'},
                      children: [
                        {
                          name: "periods_edit",
                          path: "/",
                          component: () => import("@/views/content/trips/periods/PeriodsEdit"),
                          meta: {middleware: auth,cudl:'periods_update'},
                        },
                      ]
                    },
                  ]
                },
              ],
            },
            {
              path: "transport",
              component: () => import("@/views/content/transport/index"),
              meta: {middleware: auth},
              children: [
                {
                  name: "transport_list",
                  path: "/",
                  component: () => import("@/views/content/transport/TransportList"),
                  meta: {middleware: auth,cudl:'list'},
                },
                {
                  name: "transport_create",
                  path: "create",
                  component: () => import("@/views/content/transport/TransportEditForm"),
                  meta: {middleware: auth,cudl:'create'},
                },
                {
                  path: ":id",
                  component: () => import("@/views/content/transport/TransportView"),
                  meta: {middleware: auth,cudl:'update'},
                  children: [
                    {
                      name: "transport_edit",
                      path: "/",
                      component: () => import("@/views/content/transport/TransportEdit"),
                      meta: {middleware: auth,cudl:'update'},
                    },
                  ]
                },
              ]
            },
            {
              path: "entries",
              component: () => import("@/views/content/entries/index"),
              meta: {middleware: auth},
              children: [
                {
                  name: "entries_list",
                  path: "/",
                  component: () => import("@/views/content/entries/EntriesList"),
                  meta: {middleware: auth,cudl:'list'},
                },
                {
                  name: "entries_create",
                  path: "create",
                  component: () => import("@/views/content/entries/EntriesEditForm"),
                  meta: {middleware: auth,cudl:'create'},
                },
                {
                  path: ":id",
                  component: () => import("@/views/content/entries/EntriesView"),
                  meta: {middleware: auth,cudl:'update'},
                  children: [
                    {
                      name: "entries_edit",
                      path: "/",
                      component: () => import("@/views/content/entries/EntriesEdit"),
                      meta: {middleware: auth,cudl:'update'},
                    },
                  ]
                },
              ]
            },
            {
              path: "rooms",
              component: () => import("@/views/content/rooms/index"),
              meta: {middleware: auth},
              children: [
                {
                  name: "rooms_list",
                  path: "/",
                  component: () => import("@/views/content/rooms/RoomsList"),
                  meta: {middleware: auth,cudl:'list'},
                },
                {
                  name: "rooms_create",
                  path: "create",
                  component: () => import("@/views/content/rooms/RoomsEditForm"),
                  meta: {middleware: auth,cudl:'create'},
                },
                {
                  path: ":id",
                  component: () => import("@/views/content/rooms/RoomsView"),
                  meta: {middleware: auth,cudl:'update'},
                  children: [
                    {
                      name: "rooms_edit",
                      path: "/",
                      component: () => import("@/views/content/rooms/RoomsEdit"),
                      meta: {middleware: auth,cudl:'update'},
                    },
                  ]
                },
              ]
            },
            {
              path: "addons",
              component: () => import("@/views/content/addons/index"),
              meta: {middleware: auth},
              children: [
                {
                  name: "addons_list",
                  path: "/",
                  component: () => import("@/views/content/addons/AddonsList"),
                  meta: {middleware: auth,cudl:'list'},
                },
                {
                  name: "addons_create",
                  path: "create",
                  component: () => import("@/views/content/addons/AddonsEditForm"),
                  meta: {middleware: auth,cudl:'create'},
                },
                {
                  path: ":id",
                  component: () => import("@/views/content/addons/AddonsView"),
                  meta: {middleware: auth,cudl:'update'},
                  children: [
                    {
                      name: "addons_edit",
                      path: "/",
                      component: () => import("@/views/content/addons/AddonsEdit"),
                      meta: {middleware: auth,cudl:'update'},
                    },
                  ]
                },
              ]
            },
            {
              path: "discounts",
              component: () => import("@/views/content/discounts/index"),
              meta: {middleware: auth},
              children: [
                {
                  name: "discounts_list",
                  path: "/",
                  component: () => import("@/views/content/discounts/DiscountsList"),
                  meta: {middleware: auth,cudl:'list'},
                },
                {
                  name: "discounts_create",
                  path: "create",
                  component: () => import("@/views/content/discounts/DiscEditForm"),
                  meta: {middleware: auth,cudl:'create'},
                },
                {
                  path: ":id",
                  component: () => import("@/views/content/discounts/DiscountsView"),
                  meta: {middleware: auth,cudl:'update'},
                  children: [
                    {
                      name: "discounts_edit",
                      path: "/",
                      component: () => import("@/views/content/discounts/DiscountsEdit"),
                      meta: {middleware: auth,cudl:'update'},
                    },
                  ]
                },
              ]
            },
          ]
        },

        // SETTINGS
        {
          name: "settings",
          path: "settings",
          component: () => import("@/views/settings/index"),
          meta: {middleware: auth},
          children: [
            {
              path: "account",
              name: "account",
              component: () => import("@/views/settings/account"),
              meta: {middleware: auth},
            },
            {
              path: "profile",
              name: "profile",
              component: () => import("@/views/settings/profile"),
              meta: {middleware: auth},
            },
            {
              path: "cancel-policy",
              component: () => import("@/views/settings/cancel_policy"),
              meta: {middleware: auth, cudl:'cancel_policy'},
              children: [
                {
                  name: "cp_list",
                  path: "/",
                  component: () => import("@/views/settings/cancel_policy/CPList"),
                  meta: {middleware: auth, cudl:'list'},
                },
                {
                  name: "cp_create",
                  path: "create",
                  component: () => import("@/views/settings/cancel_policy/CPEditForm"),
                  meta: {middleware: auth,cudl:'create'},
                },
                {
                  path: ":id",
                  component: () => import("@/views/settings/cancel_policy/CPView"),
                  meta: {middleware: auth,cudl:'update'},
                  children: [
                    {
                      name: "cp_edit",
                      path: "/",
                      component: () => import("@/views/settings/cancel_policy/CPEditForm"),
                      meta: {middleware: auth,cudl:'update'},
                    },
                  ]
                },
              ]
            },
            {
              path: "suppliers",
              component: () => import("@/views/settings/suppliers"),
              meta: {middleware: auth, cudl:'suppliers'},
              children: [
                {
                  name: "suppliers_list",
                  path: "/",
                  component: () => import("@/views/settings/suppliers/SupplierList"),
                  meta: {middleware: auth, cudl:'list'},
                },
                {
                  name: "suppliers_create",
                  path: "create",
                  component: () => import("@/views/settings/suppliers/SupplierEditForm"),
                  meta: {middleware: auth,cudl:'create'},
                },
                {
                  path: ":id",
                  component: () => import("@/views/settings/suppliers/SupplierView"),
                  meta: {middleware: auth,cudl:'update'},
                  children: [
                    {
                      name: "suppliers_edit",
                      path: "/",
                      component: () => import("@/views/settings/suppliers/SupplierEditForm"),
                      meta: {middleware: auth,cudl:'update'},
                    },
                  ]
                },
              ]
            },
            {
              path: "announce",
              component: () => import("@/views/settings/announce_office"),
              meta: {middleware: auth, cudl:'announce'},
              children: [
                {
                  name: "announce_list",
                  path: "/",
                  component: () => import("@/views/settings/announce_office/List"),
                  meta: {middleware: auth, cudl:'list'},
                },
                {
                  name: "announce_create",
                  path: "create",
                  component: () => import("@/views/settings/announce_office/EditForm"),
                  meta: {middleware: auth,cudl:'create'},
                },
                {
                  path: ":id",
                  component: () => import("@/views/settings/announce_office/View"),
                  meta: {middleware: auth,cudl:'update'},
                  children: [
                    {
                      name: "announce_edit",
                      path: "/",
                      component: () => import("@/views/settings/announce_office/EditForm"),
                      meta: {middleware: auth,cudl:'update'},
                    },
                  ]
                },
              ]
            },
            {
              path: "book-modify",
              name: "book_modify",
              component: () => import("@/views/settings/book_modify"),
              meta: {middleware: auth, cudl:'book_modify'},
            },
            {
              path: "options",
              component: () => import("@/views/settings/option_details"),
              meta: {middleware: auth, cudl:'options'},
              children: [
                {
                  name: "options_list",
                  path: "/",
                  component: () => import("@/views/settings/option_details/List"),
                  meta: {middleware: auth, cudl:'list'},
                },
                {
                  name: "options_create",
                  path: "create",
                  component: () => import("@/views/settings/option_details/EditForm"),
                  meta: {middleware: auth,cudl:'create'},
                },
                {
                  path: ":id",
                  component: () => import("@/views/settings/option_details/View"),
                  meta: {middleware: auth,cudl:'update'},
                  children: [
                    {
                      name: "options_edit",
                      path: "/",
                      component: () => import("@/views/settings/option_details/EditForm"),
                      meta: {middleware: auth,cudl:'update'},
                    },
                  ]
                },
              ]
            },
            {
              path: "partners",
              component: () => import("@/views/settings/partners"),
              meta: {middleware: auth, cudl:'partners'},
              children: [
                {
                  name: "partners_list",
                  path: "/",
                  component: () => import("@/views/settings/partners/PartnerList"),
                  meta: {middleware: auth, cudl:'list'},
                },
                {
                  name: "partners_create",
                  path: "create",
                  component: () => import("@/views/settings/partners/PartnerEditForm"),
                  meta: {middleware: auth,cudl:'create'},
                },
                {
                  path: ":id",
                  component: () => import("@/views/settings/partners/PartnerView"),
                  meta: {middleware: auth,cudl:'update'},
                  children: [
                    {
                      name: "partners_edit",
                      path: "/",
                      component: () => import("@/views/settings/partners/PartnerEditForm"),
                      meta: {middleware: auth,cudl:'update'},
                    },
                  ]
                },
              ]
            },
            {
              path: "users",
              component: () => import("@/views/settings/users"),
              meta: {middleware: auth, cudl:'users'},
              children: [
                {
                  name: "users_list",
                  path: "/",
                  component: () => import("@/views/settings/users/UsersList"),
                  meta: {middleware: auth, cudl:'list'},
                },
                {
                  name: "users_create",
                  path: "create",
                  component: () => import("@/views/settings/users/UsersEditForm"),
                  meta: {middleware: auth,cudl:'create'},
                },
                {
                  path: ":id",
                  component: () => import("@/views/settings/users/UsersView"),
                  meta: {middleware: auth,cudl:'update'},
                  children: [
                    {
                      name: "users_edit",
                      path: "/",
                      component: () => import("@/views/settings/users/UsersEditForm"),
                      meta: {middleware: auth,cudl:'update'},
                    },
                  ]
                },
              ]
            },
          ]
        },

        // Dashboard

        {
          name: "Analytical",
          path: "dashboards/analytical",
          component: () => import("@/views/dashboards/analytical/Analytical"),
          meta: {
            middleware: auth,
          },
        },

        // Bookings
        {
          name: "bookings",
          path: "bookings",
          component: () => import("@/views/bookings/index"),
          meta: {middleware: auth},
          children: [
            {
              name: "bookings_list",
              path: "/",
              component: () => import("@/views/bookings/BookingsList"),
              meta: {middleware: auth,cudl:'list'},
            },
            {
              name: "bookings_periods",
              path: "periods",
              component: () => import("@/views/bookings/BookingsListPeriods"),
              meta: {middleware: auth,cudl:'list'},
            },
            {
              name: "bookings_create",
              path: "create",
              component: () => import("@/views/bookings/Toma"),
              meta: {middleware: auth,cudl:'create'},
            },
            {
              path: ":id",
              component: () => import("@/views/bookings/BookingsView"),
              meta: {middleware: auth,cudl:'update'},
              children: [
                {
                  name: "bookings_edit",
                  path: "/",
                  component: () => import("@/views/bookings/BookingsEdit"),
                  meta: {middleware: auth,cudl:'update'},
                },
                {
                  name: "bookings_mails",
                  path: "mails",
                  component: () => import("@/views/bookings/Mails/"),
                  meta: {middleware: auth,cudl:'update'},
                  children: [
                    {
                      name: "booking_mail",
                      path: ":uuid",
                      component: () => import("@/views/bookings/Mails/View"),
                      meta: {middleware: auth,cudl:'update'},
                    }
                  ]
                },
                {
                  name: "bookings_history",
                  path: "history",
                  component: () => import("@/views/bookings/BookingsHistory"),
                  meta: {middleware: auth,cudl:'update'},
                },
              ]
            },
          ]
        },

        // TEST
        {
          name: "Email",
          path: "apps/email/inbox",
          component: () => import("@/views/apps/email/Email"),
          children: [
            {
              path: "/apps/email/:mailType",
              name: "MailIndex",
              component: () => import("@/views/apps/email/EmailList.vue"),
            },
            {
              path: "/apps/email/:mailType/0/:uuid",
              name: "MailDetail1",
              component: () => import(`@/views/apps/email/EmailDetail.vue`),
            },
            {
              path: "/apps/email/0/:mailTag",
              name: "MailTag",
              component: () => import("@/views/apps/email/EmailList.vue"),
            },
            {
              path: "/apps/email/0/:mailTag/0/:uuid",
              name: "MailDetail",
              component: () => import(`@/views/apps/email/EmailDetail.vue`),
            },
          ],
        },


      ],
    },

    {
      path: "/auth",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "login",
          path: "login",
          component: () => import("@/views/authentication/BoxedLogin"),
        },
        {
          name: "forgot",
          path: "forgot",
          component: () => import("@/views/authentication/ForgotPassword"),
        },
        {
          name: "activate",
          path: "activate/:activation",
          component: () => import("@/views/authentication/Activation"),
        },
        {
          name: "recover",
          path: "recover/:activation",
          component: () => import("@/views/authentication/ResetPassword"),
        },
      ]
    },


    {
      path: "*",
      component: () => import("@/views/authentication/Error"),
    },
  ],
});

import NProgress from "nprogress";

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;

<template>
<span>
  <v-icon v-if="item[itemKey]" small color="success">mdi-checkbox-marked-outline</v-icon>
  <v-icon v-else small color="danger">mdi-checkbox-blank-outline</v-icon>
</span>
</template>

<script>
  export default {
    props: {
      item: {
        type: Object,
        default: function() {
          return {}
        }
      },

      itemKey: {
        type: String,
        default: "active"
      },
    },
  }
</script>

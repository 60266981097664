<template>
<span>
  <v-icon v-if="value" small>{{ icon }}</v-icon>
</span>
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        default: null
      },
    },

    computed: {

      // "catalog": "Catalog",
      // "earlybird": "Firstminute",
      // "lastmin": "Lastminute",
      // "ski-pack": "Ski Package",
      // "package": "Package",
      // "trip": "Bus",
      // "busplane": "Bus+Plane",
      // "busplaneship": "Bus+Plane+Ship",
      // "cruise": "Cruise",
      // "plane": "Plane",
      // "train": "Train",
      // "van": "Van",
      // "event": "Event",
      // "transport": "Transport",
      // "insurance": "Insurance",
      // "misc": "Miscellaneous",
      // "tour": "Tour"
      // ["trip", "plane", "cruise", "busplane", "busplaneship", "train", "charterlet", "notransfer"],
      icon() {
        switch(this.value) {
          case "trip": return "mdi-bus"
          case "plane": return "mdi-airplane"
          case "cruise": return "mdi-ferry"
          case "busplane": return "mdi-plane-train"
          case "train": return "mdi-train"
          default: return "mdi-bus"
        }
      },
    },
  }
</script>
